import React from 'react'
import { DrvButton } from '../DrvButton/DrvButton'
import { JobCardContent, JobTitle, JobLocation, JobShortDescriptionList, JobDescription} from './DrvJobCard.styles'

const DrvJobCard = (jobs) => {
    let data = jobs.data;
    return (
        <>
            {
                data.map((item,index) =>(

                    <JobCardContent key={index} className="flex flex-col">
                        <JobTitle>
                            {item.title}
                        </JobTitle>
                        <JobLocation>
                            {item.data.location}, {item.data.employmentType}
                        </JobLocation>
                        <JobDescription>
                            {item.data.jobDescription}
                        </JobDescription>
                        <JobShortDescriptionList>
                            {item.data.shortPoints}
                        </JobShortDescriptionList>
                        <DrvButton
                            to={`/careers-open?aW5kZXg=${index}`}
                            className="w-fit mt-3"
                            background="transparent"
                            color="#545454"
                            hoverbackground="var(--primary-color)"
                            hoverborder="var(--primary-color)"
                            hovercolor="#fff">
                            Learn More
                        </DrvButton>
                    </JobCardContent>
                ))
            }
        </>

    )
}

export default DrvJobCard
