import styled from "styled-components";

export const JobCardContent = styled.div`
    font-family: 'Montserrat', sans-serif;
    color: #808080;
    border: 1px solid #f0f0f0;
    background-color: #f7f7f7;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 5px;
    flex-basis: calc(50% - 20px);
    margin: 10px;
    @media screen and (max-width: 976px){
        flex-basis: 100%;
    }
`;
export const JobTitle = styled.div`
    font-size: 30px;
    color: #282828;
    line-height: 48px;
    font-weight: 300;
`;

export const JobLocation = styled.div`
    font-size: 17px;
    color: #777777;
    line-height: 2;
    font-weight: 300;
`;

export const JobDescription = styled.p`
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    margin: 0 0 26px;
`;

export const JobShortDescriptionList = styled.ul`
    list-style: disc;
    padding-left: 40px;
    font-weight: 300;
    li{
        line-height: 1.6;
    }
`;