import styled from "styled-components";

export const JobContainer = styled.div`

    background-color: #f8f8f8;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
`;
export const DrvJobCard = styled.div`

`;